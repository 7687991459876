/**
  * Prefix all declaration with .wl-app
  * for custom adjustement use the partnerId class
  * ex: .wl-app.groupe_investors {}
**/

.wl-app {
  .modal .head__icon,
  .footer-logo,
  .banks-icon,
  .top-navbar-logo .bn-icon {
    display: none;
  }
}
