@import "~@bnc-ui/tokens/dist/styles/index.scss";

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: $ff-system;
  text-rendering: optimizeLegibility;
  height: 100%;
  background-color: $white;
}

#root {
  height: 100%;
}

button {
  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    outline: 0;
  }
}

a {
  text-decoration: none;
  color: $electric-blue;
  font-family: $ff-system;
  font-weight: normal;
  font-size: 0.875rem;

  &:hover {
    color: $black;
    cursor: pointer;
  }
}

sup {
  line-height: 0;
  font-size: 0.5em;
}

/* =============================================================================
  UTILS
============================================================================= */
.hide {
  display: none;
}

.a11y-hide {
  position: absolute;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.show {
  display: block;
}

.success {
  color: $mint;
}

.error {
  color: $brand-red;
}

.is-notActive {
  display: none;
}

/* inhibit the clear button proposed by Internet explorer and edge to avoid problem of updating data store when clicking on it */
input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

hr {
  border-color: $grey100;
  margin: 1.5em 0;

  &[class^="sc-"] {
    margin: 0.5rem 0;
  }
}

/* =============================================================================
  IE 11 HACK
============================================================================= */
/* stylelint-disable */
_:-ms-fullscreen,
:root input[type=checkbox]::-ms-check,
:root input[type=radio]::-ms-check {
  border-color: $grey100;
}

_:-ms-fullscreen,
:root input[type=checkbox]:checked::-ms-check {
  color: $white;
  background: $electric-blue;
}

_:-ms-fullscreen,
:root input[type=radio]:checked::-ms-check {
  color: $electric-blue;
  background: $white;
}

/* disable ie10/11 eye icon in password type inputs */
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

/* stylelint-enable */

/* =============================================================================
  FORMS
============================================================================= */

select {
  text-decoration: none;
  outline: none;
  font-size: 1em;
  font-family: $ff-system;
  font-weight: normal;
  line-height: 1.25em;
  height: 40px;
  width: 100%;
  border-color: $grey100;
  background: $white;
  border: 1px solid $grey100;
  color: $black;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding-left: 0.75em;

  &[disabled] {
    opacity: 0.3;
    background-color: $grey100;
    cursor: not-allowed;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $black;
  }

  &::-ms-value {
    color: $black;
    background: transparent;
  }
}

textarea {
  padding: 0.625em 1em;
  resize: none;
  font-size: 1em;
  font-family: $ff-system;
  font-weight: normal;
  line-height: 1.25;
  border: 1px solid $grey100;
  border-radius: 8px;
  color: $grey700;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.14);
  box-sizing: border-box;
  transition: all 0.2s;
  -webkit-appearance: none; /* for box shadows to show on iOS */

  &:focus {
    outline: none;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(0, 0, 0, 0.14);
    transition: all 0.2s;
  }

  &.error {
    color: $black;
    background: $candy;
    border: 1px solid $brand-red;
    box-shadow: inset 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  }

  &[disabled] {
    opacity: 0.3;
    background-color: $grey100;
  }
}

.label-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: $navy600;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: $navy600;
  opacity: 1;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: $navy600;
  opacity: 1;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $navy600;
}

.select-field.error .Select-control,
.form-group__input.error .select-field .Select-control,
.form-group__input.error select,
select.error {
  color: $black;
  background: $candy;
  border: 1px solid $brand-red;
  box-shadow: inset 0 1px 2px 1px rgba(0, 0, 0, 0.1);
}

.input_text {
  font-size: 0.75em;
  font-family: $ff-system;
  font-weight: normal;
  color: $navy600;
  letter-spacing: 0.05em;
  margin: 0 1em;
  padding: 0 1rem;
}

.input_text-error {
  color: $brand-red;
}

.form__group {
  margin-bottom: 1em;

  input {
    width: 100%;
  }

  .is-inline {
    > span {
      flex: 1 1 35%;
    }

    .form__input {
      position: relative;
      flex: 2 1 65%;
    }
  }
}

.form_errorMessageContainer {
  border-radius: 6px;
  background-color: $candy;
  color: $brand-red;
  padding: 2em 3.5em;
}

.form-field-display {
  font-family: $ff-system;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: $black;
  text-align: left;
}

.form-field-label {
  padding: 0.625em 1em 0 0;
}

/* =============================================================================
  ACCESSIBILITY
============================================================================= */

/* Hidding autofills buttons for Safari and IE in inputs */
#root input::-webkit-credentials-auto-fill-button,
#root input::-webkit-contacts-auto-fill-button,
#root input::-webkit-caps-lock-indicator {
  display: none !important;
}

#root button.bt_cta {
  border: 1px solid $electric-blue;
  color: $electric-blue;
}

#root button.bt_cta.primary {
  color: $white;
  border-color: $electric-blue;
  background-color: $electric-blue;
}

#root .bt_cta:hover,
#root .bt_cta:focus {
  color: $white;
  border-color: $electric-blue;
  background-color: $electric-blue;
}

#root .bt_cta.primary:hover,
#root .bt_cta.primary:focus {
  color: $electric-blue;
  border-color: $white;
  background-color: $white;
}

#root .bt_cta:not([disabled])::after {
  opacity: 0;
}

#root .bt_cta:hover:not([disabled])::after {
  opacity: 1;
}

#root button:focus,
#root a:focus,
#root input:not([type=checkbox]):not([type=radio]):focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(21, 114, 197, 0.2);
}

#root .error:focus {
  box-shadow: 0 0 0 3px rgba(228, 28, 35, 0.2);
}

#root input[type="text"],
#root input[type="email"],
#root input[type="tel"],
#root input[type="number"] {
  border-radius: 6px;
  padding: 0 1rem;
  height: 3rem;
  line-height: 2.5rem;
  font-weight: 500;
  font-size: 1rem;
}

#root input[type="text"].labeledtext__input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#root input:not([type=checkbox]):not([type=radio]):focus {
  position: relative;
  z-index: 1;
}

#root input[type="radio"] {
  background-color: $ice;
}

#root input[type="radio"]:checked {
  background-color: $white;
}

#root .input-password__icon {
  z-index: 100;
}

.bt_link {
  font-size: 1rem;
  text-align: left;
  font-family: $ff-system;
}

#root h1.title,
#root .title.txt-1 {
  margin-bottom: 0.5rem;
  font-size: 2rem;
  line-height: normal;
  font-weight: bold;
  text-align: left;
}

#root h2,
#root h2.title,
#root .title.txt-2 {
  font-size: 1.25rem;
  font-weight: 500;
}

#root .tooltip-container .bn-icon {
  color: $electric-blue;
}
