@import "@nbc-design/tokens/dist/styles/index.scss";

.account-locked-template {
  justify-content: space-between;

  & .template-fullscreen-footer__header,
  & .template-fullscreen-footer__main {
    max-width: 31.25rem;
    width: 100%;
  }

  & .template-fullscreen-footer__content {
    flex: 1;
  }

  & .template-fullscreen-footer__header {
    display: block;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
  }

  & .template-fullscreen-footer__footer {
    max-height: 12.25rem;
    box-shadow: none;
  }
}
