@import "~@bnc-ui/tokens/dist/styles/index.scss";

.template-column {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.template-column .template-column__content {
  top: 0;
  left: 0;
  bottom: 0%;
  width: 90vw;
  text-align: center;
  padding: 0 5vw;
  background: $white;
  overflow: auto;
}

.template-column .template-column__content--left {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100vh;
  z-index: 3;
}

.template-column .template-column__content--right {
  display: block;
  height: 100vh;
  background-color: $ice;
}

.template-column .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
  flex: none;
}

.template-column .main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5vw;
}

.template-column .main > * {
  width: 100%;
}

.template-column .footer .toggle-field {
  max-width: 270px;
  margin: 0 auto 2em;
}

.template-column .template-column__content .main .form-group__label {
  text-align: left;
}

@media screen and ( max-width: $bp-small ) {
  .template-column {
    display: block;
    min-height: 100vh;
  }

  .template-column__content .template-column__content--left {
    position: relative;
    opacity: 100%;
  }

  .template-column .footer .toggle-field {
    margin-bottom: 2em;
  }

  .template-column .footer {
    margin-bottom: 2.5em;
  }
}

@media ( max-width: $bp-xsmall ) {
  .template-column .main > * {
    position: relative;
    top: 0;
    transform: translateY(0);
  }
}

@media ( orientation: landscape ) {
  .template-column .header {
    padding: 1em 0;
  }
}

/* Safari hacks */

/* Safari 10.1+ */
@media not all and ( min-resolution: 0.001dpcm ) {
  @media screen and ( max-width: $bp-small ) {
    .template-column .template-column__content {
      position: relative;
    }
  }
}

/* Safari 6.1-10.0 (not 10.1, which is the latest version of Safari at this time) */
@media screen and ( min-color-index: 0 ) and ( -webkit-min-device-pixel-ratio: 0 ) {
  @media screen and ( max-width: $bp-small ) {
    .template-column .template-column__content {
      position: relative;
    }
  }
}
